import { createContext } from "preact";
import { useState } from 'preact/hooks';

const cities = [
  {
    id: 'aparecida-de-goiania',
    displayName: 'Aparecida de Goiânia',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2000
  },
  {
    id: 'barueri',
    displayName: 'Barueri',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2160
  },
  {
    id: 'belo-horizonte',
    displayName: 'Belo Horizonte',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2150
  },
  {
    id: 'betim',
    displayName: 'Betim',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1700,
    price: 2290
  },
  {
    id: 'blumenau',
    displayName: 'Blumenau',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2400
  },
  {
    id: 'canoas',
    displayName: 'Canoas',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2060
  },
  {
    id: 'camboriu',
    displayName: 'Camboriú',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2070
  },
  {
    id: 'campinas',
    displayName: 'Campinas',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2100,
    price: 2280
  },
  {
    id: 'cascavel',
    displayName: 'Cascavel',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2030
  },
  {
    id: 'caxias-do-sul',
    displayName: 'Caxias do Sul',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2320
  },
  {
    id: 'chapeco',
    displayName: 'Chapecó',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2070
  },
  {
    id: 'colombo',
    displayName: 'Colombo',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2300
  },
  {
    id: 'contagem',
    displayName: 'Contagem',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2380
  },
  {
    id: 'curitiba',
    displayName: 'Curitiba',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2300
  },
  {
    id: 'diadema',
    displayName: 'Diadema',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2200
  },
  {
    id: 'florianopolis',
    displayName: 'Florianópolis',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2480
  },
  {
    id: 'foz-do-iguacu',
    displayName: 'Foz do Iguaçu',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1700,
    price: 2200
  },
  {
    id: 'goiania',
    displayName: 'Goiânia',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2000
  },
  {
    id: 'governador-valadares',
    displayName: 'Governador Valadares',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1800,
    price: 2040
  },
  {
    id: 'guarulhos',
    displayName: 'Guarulhos',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2160
  },
  {
    id: 'itajai',
    displayName: 'Itajaí',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2300
  },
  {
    id: 'joinville',
    displayName: 'Joinville',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2250
  },
  {
    id: 'juiz-de-fora',
    displayName: 'Juiz de Fora',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 1850
  },
  {
    id: 'jundiai',
    displayName: 'Jundiaí',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2200,
    price: 2200
  },
  {
    id: 'limeira',
    displayName: 'Limeira',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2100,
    price: 2180
  },
  {
    id: 'londrina',
    displayName: 'Londrina',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2250
  },
  {
    id: 'maringa',
    displayName: 'Maringá',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2140
  },
  {
    id: 'mogi-das-cruzes',
    displayName: 'Mogi das Cruzes',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2100
  },
  {
    id: 'montes-claros',
    displayName: 'Montes Claros',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1600,
    price: 1950
  },
  {
    id: 'novo-hamburgo',
    displayName: 'Novo Hamburgo',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2140
  },
  {
    id: 'osasco',
    displayName: 'Osasco',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2260
  },
  {
    id: 'pelotas',
    displayName: 'Pelotas',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1810,
    price: 2300
  },
  {
    id: 'piracicaba',
    displayName: 'Piracicaba',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2100,
    price: 2280
  },
  {
    id: 'ponta-grossa',
    displayName: 'Ponta Grossa',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1900,
    price: 1900
  },
  {
    id: 'porto-alegre',
    displayName: 'Porto Alegre',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2060
  },
  {
    id: 'ribeirao-preto',
    displayName: 'Ribeirão Preto',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2100
  },
  {
    id: 'ribeirao-das-neves',
    displayName: 'Ribeirão das Neves',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2500
  },
  {
    id: 'rio-grande',
    displayName: 'Rio Grande',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1870,
    price: 2100
  },
  {
    id: 'sao-bernardo-do-campo',
    displayName: 'São Bernardo do Campo',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2250
  },
  {
    id: 'sao-paulo',
    displayName: 'São Paulo',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2630
  },
  {
    id: 'sao-jose',
    displayName: 'São José',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2140
  },
  {
    id: 'sao-jose-dos-campos',
    displayName: 'São José dos Campos',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2140
  },
  {
    id: 'sao-jose-do-rio-preto',
    displayName: 'São José do Rio Preto',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2100,
    price: 2100
  },
  {
    id: 'santa-maria',
    displayName: 'Santa Maria',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2100
  },
  {
    id: 'santo-andre',
    displayName: 'Santo André',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2240
  },
  {
    id: 'sorocaba',
    displayName: 'Sorocaba',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2280
  },
  {
    id: 'suzano',
    displayName: 'Suzano',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2160
  },
  {
    id: 'uberaba',
    displayName: 'Uberaba',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 1900,
    price: 2100
  },
  {
    id: 'uberlandia',
    displayName: 'Uberlândia',
    feePerHour: 125,
    fee: 4767,
    pricePerHour: 2000,
    price: 2000
  }
]

const DonamaidContext = createContext([{}, () => {}]);

const DonamaidContextProvider = (props) => {
  const [state, setState] = useState({
    cities: cities,
    selectedCity: 'Porto Alegre',
    popupDisplayed: false
  });

  return (
    <DonamaidContext.Provider value={[state, setState]}>
      { props.children }
    </DonamaidContext.Provider>
  )
}

export { DonamaidContext, DonamaidContextProvider };